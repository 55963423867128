import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import close from "../../Assets/close.svg";
import menuArrow from "../../Assets/menuArrow.svg";
import { Link } from "react-router-dom";

const Menu = () => {
  const { setMenuOpen } = useContext(AppContext);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <section className="flex flex-col p-[24px] justify-between items-end bg-[#E8E8E3] z-50 fixed bottom-0 left-0 right-0 top-0">
      {/* Title and Close */}
      <div className="w-full flex items-start justify-between">
        <h2 className="text-[50px] sm:text-[96px] text-white sm:leading-[76px] leading-[40px]">
          Menu
        </h2>

        <button onClick={closeMenu}>
          <img
            src={close}
            alt=""
            className="h-[14px] w-[14px] md:h-[22.04px] md:w-[22.94px]"
          />
        </button>
      </div>

      {/* Links */}
      <div className="text-[#303739] md:max-w-[50%] w-full">
        {/* SP-01 */}
        <Link onClick={closeMenu} to="/sp01-info">
          <div className="text-[30px] pt-[3px] pb-[16px] sm:pt-[6px] sm:pb-[24px] leading-[36px] sm:text-[72px] sm:leading-[72px] h-[55px] sm:h-[102px] border-t border-[#303739] w-full flex justify-between">
            SP–01{" "}
            <img
              src={menuArrow}
              alt=""
              className="h-[40px] sm:h-[72px] sm:w-[72px] w-[36px]"
            />
          </div>
        </Link>

        {/* Shop */}
        <Link onClick={closeMenu} to="/shop">
          <div className="text-[30px] pt-[3px] pb-[16px] sm:pt-[6px] sm:pb-[24px] leading-[36px] sm:text-[72px] sm:leading-[72px] h-[55px] sm:h-[102px] border-t border-[#303739] w-full flex justify-between">
            Shop
            <img
              src={menuArrow}
              alt=""
              className="h-[40px] sm:h-[72px] sm:w-[72px] w-[36px]"
            />
          </div>
        </Link>

        {/* Listen */}
        <Link onClick={closeMenu} to="/listen">
          <div className="text-[30px] pt-[3px] pb-[16px] sm:pt-[6px] sm:pb-[24px] leading-[36px] sm:text-[72px] sm:leading-[72px] h-[55px] sm:h-[102px] border-t border-[#303739] w-full flex justify-between">
            Listen
            <img
              src={menuArrow}
              alt=""
              className="h-[40px] sm:h-[72px] sm:w-[72px] w-[36px]"
            />
          </div>
        </Link>

        {/* Info */}
        <Link onClick={closeMenu} to="/info">
          <div className="text-[30px] pt-[3px] pb-[16px] sm:pt-[6px] sm:pb-[24px] leading-[36px] sm:text-[72px] sm:leading-[72px] h-[55px] sm:h-[102px] border-t border-[#303739] w-full flex justify-between">
            Info
            <img
              src={menuArrow}
              alt=""
              className="h-[40px] sm:h-[72px] sm:w-[72px] w-[36px]"
            />
          </div>
        </Link>
      </div>
    </section>
  );
};

export default Menu;
