import Hero from "./Hero";
import Gradient from "./Gradient";
import Materials from "./Materials";
import ReactGA from 'react-ga4';
import Features from "./Features";

const SP01Info = () => {

  ReactGA.send({ hitType: "pageview", page: "/sp01-info", title: "SPO1 Info" });


  return (
    <>
      <Hero />
      <Gradient />
      <Materials />
      <Features />
    </>
  );
};

export default SP01Info;
