import { storyblokEditable } from "@storyblok/react";

const SubheadingParagraph = ({ blok }) => {
    return (
        <h2 className="text-[16px] text-left md:text-[24px] w-full tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]" {...storyblokEditable(blok)}>
            {blok.text}
        </h2>
    );
};

export default SubheadingParagraph;