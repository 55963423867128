import { useContext, useEffect, useRef, useState } from "react";
import pause from "../../Assets/pause.svg";
import play from "../../Assets/play.svg";
import Mar from "../Marquee";
import { AppContext } from "../../context/appContext";
import { ShopContext } from "../../context/shopContext";
import data from "../../Assets/Music/data";

const MP3Player = () => {
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [hasBeenPlayed, setHasBeenPlayed] = useState(false);
  const [cartCount, setCartCount] = useState(0);

  const { file, isPlaying, setIsPlaying, setOpenCart, activeSong } = useContext(AppContext);
  const { checkout } = useContext(ShopContext);

  useEffect(() => {
    if (hasBeenPlayed) {
      setIsPlaying(true);
      audioRef.current.play();
    }

    const audioElement = audioRef.current;

    const updateTime = () => {
      setCurrentTime(audioElement.currentTime);
    };

    const updateDuration = () => {
      setDuration(audioElement.duration);
    };

    audioElement.addEventListener("timeupdate", updateTime);
    audioElement.addEventListener("loadedmetadata", updateDuration);
  }, [file]);

  const togglePlay = () => {
    setHasBeenPlayed(true);
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying((prev) => !prev);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    if (checkout && checkout.lineItems) {
      setCartCount(checkout.lineItems.length)
    }
  }, [checkout]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <div className=" h-[48px] z-10 bg-[#e8e8e3] fixed bottom-0 flex w-full">
      {/* {
        cartCount > 0 && <div onClick={() => setOpenCart(true)} className=" absolute max-w-[200px] backdrop-blur bottom-[75px] mr-auto ml-auto left-[0] right-[0] border-black border px-[30px] py-[5px] text-[20px] ">View my cart ({cartCount})</div>
      } */}
      <audio
        ref={audioRef}
        preload="none"
        src={activeSong}
        type="audio/mpeg"
      />
      <div className=" w-full flex items-center py-[7.5px]">
        {isPlaying && (
          <div className="absolute -z-10">
            <Mar />
          </div>
        )}

        <button
          className="ml-[16px]"
          onClick={togglePlay}
        >
          {isPlaying ? (
            <img
              src={pause}
              alt=""
              className="w-[16.5px] h-[21px] md:h-[28px] md:w-[22px]"
            />
          ) : (
            <img
              src={play}
              alt=""
              className="w-[16.5px] h-[21px] md:h-[28px] md:w-[22px]"
            />
          )}
        </button>

        <div className="ml-[12px] md:ml-[34px] flex items-center justify-center">
          {hasBeenPlayed ? (
            <span className="bg-[#303739] w-[72px] h-[26px] rounded-[4px] gap-[5px] px-[10px] flex justify-center items-center md:h-[33px] text-[#E8E8E3] tracking-[0.56px] md:tracking-[3%] leading-[18px] md:leading-[24px] md:text-[20px] text-[12px]">
              <span>{formatTime(currentTime)}</span>
            </span>
          ) : (
            <span className="bg-[#303739] w-[72px] h-[26px] rounded-[4px] gap-[5px] px-[10px] flex justify-center items-center md:h-[33px] text-[#E8E8E3] tracking-[0.56px] md:tracking-[3%] leading-[18px] md:leading-[24px] md:text-[20px] text-[12px]">
              {formatTime(duration)}
            </span>
          )}
        </div>

        <span className="bg-[#303739] overflow-hidden truncate max-w-[246px]  md:hidden h-[26px] ml-[12px] rounded-[4px] px-[10px] flex justify-center items-center md:h-[33px] tracking-[0.56px] md:tracking-[3%] leading-[18px] md:leading-[24px] md:text-[20px] text-[12px] text-[#E8E8E3]">
          <span className="overflow-hidden truncate">
            {data[file].artistName} - {data[file].SongName}
          </span>
        </span>

        <span className="bg-[#303739] overflow-hidden truncate max-w-[346px]  hidden md:flex h-[26px] ml-[125px] md:ml-0 rounded-[4px] px-[10px] justify-center items-center md:h-[33px] tracking-[0.56px] md:tracking-[3%] leading-[18px] md:leading-[24px] md:text-[20px] text-[12px] text-[#E8E8E3] md:absolute md:top-1/2 md:left-1/2 md:transform -translate-x-1/2 md:-translate-y-1/2">
          <span className="overflow-hidden truncate">
            {data[file].artistName} - {data[file].SongName}
          </span>
        </span>
      </div>
    </div>
  );
};

export default MP3Player;
