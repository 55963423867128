import ListenCard from "../../components/UI/ListenCard";
import songs from "../../Assets/Music/data";
import { useContext } from "react";
import { AppContext } from "../../context/appContext";
import ReactGA from 'react-ga4';

const Listen = () => {
  const { file, isPlaying, setIsPlaying, setOpenCart, activeSong } = useContext(AppContext);

  ReactGA.send({ hitType: "pageview", page: "/listen", title: "Listen" });

  const title = "Mix—01 Jessy Lanza";
  const desc =
    "Ac lacus dis non enim elit risus nunc nisl. Interdum eget morbi duis eu sed et. Tortor viverra dictum lacus turpis vestibulum. A morbi aliquet enim neque ac netus egestas. Diam dui gravida aliquam cras mattis erat.Odio sed sed vulputate fames nibh viverra.";

  return (
    <section className="relative w-full bg-[#E8E8E3] flex justify-center">
      {/* Gradient */}
      <div className="absolute top-0 z-10 w-full">
        <div className="h-[68px] md:h-[116px] bg-[#ffffff]"></div>
        <div className="h-[68px] md:h-[116px] bg-gradient-to-b from-[#ffffff] to-[#E8E8E3]"></div>
      </div>

      <div className="w-full flex flex-col text-white max-w-screen-3xl">
        <h2 className="text-[#2D3745] z-10 text-center mt-[84px] mb-[54px] text-[50px] leading-[40px] md:text-[96px] md:leading-[76px] md:mt-[156px] md:mb-[120px]">
          Listen
        </h2>

        <div className="md:px-[24px] px-[16px] justify-center pb-[60px] md:pb-[120px] grid md:grid-cols-2 lg:grid-cols-3 md:gap-x-[24px] gap-x-[16px] md:gap-y-[60px] gap-y-[32px] max-w-full">
          {songs.map((item, index) => (
            <ListenCard
              img={item.img}
              index={index}
              title={title}
              desc={desc}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Listen;
