import RerectButton from "../../components/RedirectButton";
import info from "../../Assets/info.png";
import ReactGA from 'react-ga4'

const Info = () => {

  ReactGA.send({ hitType: "pageview", page: "/info", title: "Info" });

  return (
    <section className="flex flex-col">
      {/* Gradient */}
      <div className="absolute top-0 z-10 w-full">
        <div className="h-[68px] md:h-[116px] bg-[#ffffff]"></div>
        <div className="h-[68px] md:h-[116px] bg-gradient-to-b from-[#ffffff] to-[#E8E8E3]"></div>
      </div>

      <div className="w-full bg-[#E8E8E3] flex-col flex justify-center">
        <h2 className="text-[#2D3745] z-10 text-center mt-[84px] mb-[54px] text-[50px] leading-[40px] md:text-[96px] md:leading-[76px] md:mt-[156px] md:mb-[120px]">
          Info
        </h2>

        {/* img and text */}
        <div className="w-full flex justify-center mb-[60px] md:mb-[64]">
          <div className="max-w-[1366px] gap-[60px] px-[16px] w-full xl:justify-between justify-center items-start flex flex-wrap">
            <div className="">
              {/* Text */}
              <div className="max-w-[528px] w-full text-[#303739] lg:text-[16px] text-[14px] lg:tracking-[0.48px] tracking-[0.56px] leading-[18px] lg:leading-[20px]">
                Zoë + Dave met around 15 years ago in a park in Montreal. Music
                brought them together as friends, and a few months later they
                were living with their partners in an industrial loft near the
                Marché Jean Talon. The space became a hub for unstructured,
                community-rooted real love for music with a pair of vintage
                Klipschorn speakers at the hearth.  <br />
                <br />
                Around 2016, a rare set of 1980’s studio monitors produced for
                the CBC were unearthed by a friend and found their way into the
                loft. Their sound was mesmerizing, prompting an ongoing
                obsession to understand the waves and frequencies of hi fidelity
                audio. A random wine bar tete-a-tete in 2017 spun into a night
                of spirited napkin scribbling, and six years later, Waves and
                Frequencies was born.
                <br />
                <br />
                Zoë Mowat is a New York-based, award-winning furniture designer who
                has run her own independent furniture design studio for over a
                decade. Her connection to materials and sculptural process is
                the aura around Waves and Frequencies’s product design and
                creative direction.
                <br />
                <br />
                Dave Shaw is an accomplished industrial designer, DJ, and audio
                enthusiast. He works in Thunder Bay, Canada as Waves and
                Frequencies’ engineer and fabricator, introducing people to the
                possibilities of hi-fidelity audio through finely tuned sound
                systems and curated events.
              </div>

              {/* Button */}

              <div className="mt-[36px] lg:flex">
                <a
                  href="mailto:info@wafaudio.com"
                  className="md:flex"
                >
                  <RerectButton text={"Contact us"} />
                </a>
              </div>
            </div>

            <img
              src={info}
              alt=""
              className="max-w-[666px] w-full"
            />
          </div>
        </div>
      </div>

      {/* Gradient => bege-to-white */}
      <div className="h-[64px] md:h-[120px] bg-gradient-to-b to-[#ffffff] from-[#E8E8E3]"></div>

      {/* Thanks Section */}
      <section className="w-full md:mb-[120px] mb-[60px] flex justify-center">
        <div className="max-w-[1632px] md:px-[24px] px-[16px] w-full flex-col items-center flex justify-center">
          <h2 className="text-[#CFC8C6] w-full text-left text-[50px] md:text-[96px] md:leading-[76px] leading-[40px] md:mt-[60px] mt-[24px]">
            Thank you
          </h2>

          {/* Desktop */}
          <div className="hidden gap-[24px] md:flex h-[160px] mt-[60px] w-full text-[16px] leading-[20px] tracking-[0.48px]">
            <div className="w-full"></div>

            <div className="w-full flex">
              <div className="max-w-[252px] w-full flex flex-col">
                <span>Adrianna Glaviano (photos)</span>
                <span>Adrian Harwood (website)</span>
                <span>Solen Montreal</span>
                <span>La Rama Records</span>
              </div>

              <div className="max-w-[252px] w-full flex flex-col">
                <span>Our Atlas loft family</span>
                <span>Wayne Elliott</span>
                <span>Home Party crew</span>
                <span>Cynthia Leung</span>
              </div>

              <div className="max-w-[252px] w-full flex flex-col">
                <span>Kay Lee</span>
                <span>Meghan Marin</span>
                <span>Eric Epstein</span>
                <span>Multi-culti space garage</span>
                <span>Zig Zag crew</span>
              </div>
            </div>
          </div>

          {/* Mobile */}
          <div className="flex md:hidden mt-[36px] gap-[5px] w-full text-[14px] leading-[18px] tracking-[0.56px]">
            <div className="w-full flex gap-[16px]">
              <div className="max-w-[171px] w-full flex flex-col">
                <span>Adrianna Glaviano (photos)</span>
                <span>Adrian Harwood (website)</span>
                <span>Solen Montreal</span>
                <span>La Rama Records</span>
                <span>Our Atlas loft family</span>
                <span>Wayne Elliott</span>
              </div>

              <div className="max-w-[171px] w-full flex flex-col">
                <span>Home Party crew</span>
                <span>Cynthia Leung</span>
                <span>Kay Lee</span>
                <span>Meghan Marin</span>
                <span>Eric Epstein</span>
                <span>Multi-culti space garage</span>
                <span>Zig Zag crew</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Info;
