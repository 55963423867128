import { storyblokEditable } from "@storyblok/react";

const Teaser = ({ blok }) => {
    return (
        <h2 className="text-[#2D3745] text-center mt-[84px] mb-[54px] text-[50px] leading-[40.2px] md:text-[96px] md:leading-[76px] md:mt-[156px] md:mb-[120px]" {...storyblokEditable(blok)}>
            {blok.headline}
        </h2>
    );
};

export default Teaser;