// Marquee.js
import React from "react";
import Marquee from "react-fast-marquee";
import bg from "../../Assets/mp3-bg.svg";

const Mar = () => {
  return (
    <Marquee
      speed={28}
      pauseOnHover
      autoFill
    >
      <img
        src={bg}
        alt=""
        className="h-full"
      />
    </Marquee>
  );
};

export default Mar;
