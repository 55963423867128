import { createContext, useState } from "react";
import data from "../Assets/Music/data";

export const AppContext = createContext();

const ContextProvider = ({ children }) => {
  const [isCartOpen, setOpenCart] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [hasSeenPopup, setHasSeenPopup] = useState(false);

  const [file, setfile] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeSong, setActiveSong] = useState(data[0].mp3File);

  return (
    <AppContext.Provider
      value={{
        isCartOpen,
        setOpenCart,
        isMenuOpen,
        setMenuOpen,
        file,
        setfile,
        isPlaying,
        setIsPlaying,
        hasSeenPopup,
        setHasSeenPopup,
        activeSong,
        setActiveSong,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
