import { storyblokEditable } from "@storyblok/react";
import { useEffect } from "react";

const Image = ({ blok }) => {

    return (
        <img className="w-full mt-[30px] mb-[30px]" src={blok.image.filename} {...storyblokEditable(blok)}>
        </img>
    );
};

export default Image;