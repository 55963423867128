import hero from "../../Assets/S907652-R1-007-2 1.jpeg";
import "../../index.css";

const Main = () => {
  return (
    <section className="w-full relative flex-col items-center flex justify-center">
      <div className="absolute w-full top-0 z-10">
        <div className="w-full h-[80px] md:h-[132px] bg-white"></div>
        <div className="w-full h-[80px] md:h-[132px] bg-gradient-to-b from-[#fff] to-[#fff0]"></div>
      </div>
      <div className="-z-10 w-full md:mt-[132px] mt-[80px] relative text-[50px] text-white items-center md:h-[75vh]  flex justify-center">
        <img
          src={hero}
          alt=""
        />
      </div>
    </section>
  );
};

export default Main;
