const RedirectButton = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="md:h-[42px] h-[36px] text-[#303739] group hover:bg-[#303739] transition-all duration-300 hover:text-white border-[1px] rounded-[4px] border-[#303739] md:gap-[6px] gap-[4px] flex items-center md:px-[24px] px-[20px] md:pb-[10px] pb-[8px] md:pt-[12px] pt-[10px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] md:text-[16px] text-[14px]"
    >
      {text}
      <svg
        width="21"
        height="14"
        viewBox="0 0 21 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-[#303739] group-hover:fill-white"
      >
        <path d="M13.8 0.199219L20.6 6.99922L13.8 13.7992L12.96 12.9392L18.3 7.59922H0V6.39922H18.3L12.96 1.05922L13.8 0.199219Z" />
      </svg>
    </button>
  );
};

export default RedirectButton;
