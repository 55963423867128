import second from "../../Assets/WAF SP-01 - 03.jpg";
import "../../index.css";
import RedirectButton from "../../components/RedirectButton";
import { Link } from "react-router-dom";

const Third = () => {
  return (
    <>
      <section className="w-full bg-[#E8E8E3] flex flex-col items-center py-10 justify-center">
        <div className="w-full flex-col-reverse items-center lg:items-start lg:flex-row justify-center gap-[36px] md:gap-[80px] xl:gap-[162px] relative max-w-screen-3xl flex px-[24px]">
          <div className="max-w-[666px] w-full">
            <img
              src={second}
              alt=""
            />
          </div>

          <div className="text-[#303739] flex flex-col items-start">
            <h2 className="flex text-[#fff] sm:text-[96px] text-[48px] mb-[20px] flex-col sm:mb-[132px] sm-[20px] sm:leading-[76px] leading-[40px]">
              <span>Introducing </span>
              <span>SP–01</span>
            </h2>
            <div className="md:max-w-[615px] w-full max-w-[318px] md:mb-[48px] mb-[36px] text-[#303739] md:text-[30px] text-[16px] leading-[20px] md:leading-[36px] md:tracking-[0.6px] tracking-[0.48px]">
              Our first creation is SP-01, a minimal yet meticulously honed
              speaker that channels the full spectrum of music’s warmth and
              richness. {" "}
            </div>
            <Link to="/sp01-info">
              <RedirectButton text={"Learn more about SP–01"} />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Third;
