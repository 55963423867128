import { useEffect } from "react";

const Card = ({ img, title, price }) => {
  return (
    <div className="flex flex-col md:gap-[24px]  gap-[16px]">
      <img
        src={img}
        alt=""
        className="max-w-[600px] xs:-h-[325px] xs:max-h-[325px] md:w-[300px] md:h-[400px] sm:max-h-[500px] "
      />
      <div className="flex flex-col">
        {/* name */}
        <span className="text-[#303739] text-[14px] tracking-[0.56px] md:tracking-[0.48px] leading-[18px] md:text-[24px] md:leading-[30px]">
          {title}
        </span>
      </div>
    </div>
  );
};

export default Card;
