import image from '../../Assets/Sound-advice.jpg';
import ReactGA from 'react-ga4';

const SoundAdvice = () => {

  ReactGA.send({ hitType: "pageview", page: "/soundadvice", title: "Sound Advice" });

  return (
    <section className="p-[16px] w-full items-center flex flex-col justify-center">
      {/* Gradient */}
      <div className="absolute top-0 -z-10 w-full">
        <div className="h-[68px] md:h-[116px] bg-[#E8E8E3]"></div>
        <div className="h-[68px] md:h-[116px] bg-gradient-to-b from-[#E8E8E3] to-[#EDEDEA00]"></div>
      </div>

      <div className="w-full max-w-[804px] flex-col">
        <h2 className="text-[#2D3745] text-center mt-[84px] mb-[54px] text-[50px] leading-[40.2px] md:text-[96px] md:leading-[76px] md:mt-[156px] md:mb-[120px]">
          Sound advice
        </h2>
        <div className="max-w-full flex justify-center flex-wrap">
          {/* Lorem */}
          <div className="max-w-[804px] w-full md:mb-[30px] mb-[30px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              Whether you have an intimate history with audio or you’re acoustically curious and just beginning your relationship with high fidelity sound, taking care to properly set up your system and space is the foundation for accessing a full soundscape.
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              We’ve collected some best practices and tips below. Feel free to drop us a line at <a href="mailto: info@wafaudio.com" className="underline">info@wafaudio.com</a> if you have any gear or setup related questions, we’d be happy to chat.
            </p>

            <img src={image} className='w-full mt-[30px] mb-[30px]' alt='' />
          </div>

          {/* Lorem */}
          <div className="max-w-[804px] w-full md:mb-[30px] mb-[60px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[24px]">
              Equipment
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              Every audio setup relies on an uncomplicated series of essential components:
              <br />
              <br />
              <strong>Speakers</strong>
              <br />
              Our SP-01 speakers, like most home stereos throughout history, are “passive”, meaning they receive their signal through wires from an external source. You can flip through different amp and sound source options over time without worrying whether your speakers are compatible.
              <br />
              <br />
              <strong>Cables</strong>
              <br />
              While it might seem like a small detail, the quality of your speaker cable can have a noticeable impact on the sound. Higher quality cables can enhance clarity and reduce signal loss. Your speakers have a pair of universal binding posts on the rear faceplate. One is positive (red) and one is negative (black). Both unscrew to expose a hole in the middle post and will accept all common formats of speaker wire connection: <strong>banana plugs</strong> (simply inserted in the hole in the end), <strong>pin connectors</strong> (unscrew the post, insert into the hole screw down to tighten), <strong>spade or lug terminals</strong> (unscrew the post, insert into the hole screw down to tighten) and <strong>bare wire</strong> (also inserted into the hole in the post). Custom finished, high quality speaker wire is available on our website with your choice of connector. If you’re sourcing your own, we recommend 16 gauge or thicker.
              <br />
              <br />
              <strong>Amplifier/Preamplifier</strong>
              <br />
              A basic amp will reproduce the signal it’s receiving from your sound source. A preamp allows you to connect multiple sources and select between them. An “integrated” amp combines both. If listening to vinyl, make sure there is a Phono channel since a turntable needs an extra stage of amplification. If not, you can purchase a small dedicated Phono Preamp. While there’s an infinite amount of choice in either new or used models, we recommend selecting something with a power rating of <strong>150 watts per channel</strong> or more to get the most out of the SP-01. This capacity ensures that your speakers receive enough power to perform at their best, reducing the risk of distortion and damage that can occur with an underpowered system. See the Recommendations section for our suggestions.
              <br />
              <br />
              <strong>Sound Source</strong>
              <br />
              Vinyl, CD’s, streaming platforms and digital libraries all have their pros and cons, and depending on your habits you might switch between them during your day. The quality of your music source is the biggest determining factor in the quality of your sound, so if possible, use the highest settings in your streaming platform and invest in a quality stylus for your turntable.
            </p>
          </div>

          {/* Lorem */}
          <div className="max-w-[804px] w-full md:mb-[30px] mb-[30px]">
            {/* Subtitle */}
            <br className='lg:visible hidden' />
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[24px]">
              Location
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              Finding the right location for speakers in your space is central to getting the best sound. Start by placing them at least a foot or two away from the walls to reduce echo and bass buildup. Try to aim for a symmetrical setup, where each speaker is an equal distance from where you sit (imagine an equilateral triangle between you and your speakers).
              <br />
              <br />
              Whether set on the floor or placed on a piece of furniture or stand, make use of the SP-01’s tilting feature to aim the cabinet towards an ear-level listening position. Your room’s acoustics play a big but invisible role as well, so feel free to iterate with angle and position until you arrive at an optimum balance.
            </p>
          </div>

          {/* Lorem */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[60px]">
            {/* Subtitle */}
            <br />
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[24px]">
              Recommendations
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              <strong>Lower Budget</strong>
              <br />
              <a href='https://fosiaudio.com/pages/2-1-channel-amplifier-bt20a-pro' className="underline">Fosi Audio BT20A-PRO </a>
              <br />
              <strong>Price:</strong> $119.99 USD
              <br />
              <strong>Details:</strong> The super compact Fosi Audio BTA20A-PRO is a ridiculously affordable entry into good quality audio. It’s no nonsense, ideal for smaller rooms, and ships with a built-in Bluetooth chip and antenna. Be sure to choose the 48v power supply if purchasing to get the maximum output from the unit.
              <br />
              <br />
              <strong>Mid Budget</strong>
              <br />
              <a href='https://apollonaudio.com/product/hypex-ncx500-ncorex-st-stereo-amplifier/' className="underline">Apollon NCx500ST</a> with <a href='https://apos.audio/collections/top-sellers/products/topping-d90se-dac' className="underline">Topping D90SE</a>
              <br />
              <strong>Price:</strong> Amp $1192.00 USD, Preamp $799.00 USD
              <br />
              <strong>Details:</strong> The Apollon NCx500ST amp combined with the Topping D90SE is a powerhouse combination of two of the best available, lowest noise units on the market. This setup offers advanced features like high-resolution audio processing and greater power output, suitable for larger rooms and enthusiasts who don’t want to worry about upgrading in the future. For the price, it’s easily an end-game system.
              <br />
              <br />
              <strong>High Budget</strong>
              <br />
              <a href='https://www.cambridgeaudio.com/usa/en/products/evo/evo-150' className="underline">Cambridge Audio EVO 150</a>
              <br />
              <strong>Price:</strong> $2999.00 USD / $3999 CAD
              <br />
              <strong>Details:</strong> The Cambridge Audio EVO 150 is an acclaimed, all-in-one unit with a huge list of features including integrated streaming, large color screen and connections for pretty much any conceivable sound source, from turntable to TV to computer. Excellent sound in an intuitive, compact package, if budget permits.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SoundAdvice;
