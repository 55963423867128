import { storyblokEditable } from "@storyblok/react";
import { render } from 'storyblok-rich-text-react-renderer';

const Paragraph = ({ blok }) => {
    return (
        <h2 className="text-left md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]" {...storyblokEditable(blok)}>
            {render(blok.text)}
        </h2>
    );
};

export default Paragraph;