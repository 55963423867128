import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ContextProvider from "./context/appContext";
import ShopContextProvider from "./context/shopContext";
import { storyblokInit, apiPlugin } from "@storyblok/react";
import Page from "./components/CMS/page";
import Grid from "./components/CMS/grid";
import Teaser from "./components/CMS/teaster";
import Feature from "./components/CMS/feature";
import SubheadingParagraph from "./components/CMS/subheading-paragraph";
import Paragraph from "./components/CMS/paragraph";
import Image from "./components/CMS/image";

storyblokInit({
  accessToken: "2dNhO6a398mQMqJQY7mkaQtt",
  use: [apiPlugin],
  components: {
    page: Page,
    grid: Grid,
    teaser: Teaser,
    feature: Feature,
    subheading: SubheadingParagraph,
    paragraph: Paragraph,
    image: Image
  },
  apiOptions: {
    region: 'us'
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <ShopContextProvider>
        <App />
      </ShopContextProvider>
    </ContextProvider>
  </React.StrictMode>
);
