import second from "../../Assets/WAF SP-01 - 04.jpg";
import "../../index.css";
import textFooter from "../../Assets/footerText.svg";

import "../../index.css";
import RedirectButton from "../../components/RedirectButton";
import { Link } from "react-router-dom";

const Forth = () => {
  return (
    <section className="w-full relative flex flex-col items-center justify-center">
      {/* Gradient */}
      <div className="h-[64px] absolute top-0 md:h-[120px] bg-gradient-to-b from-[#E8E8E3] to-[#E8E8E300] w-full"></div>

      <div className="relative flex-col w-full pt-[64px] md:pt-[159px] flex-wrap lg:flex-nowrap px-[24px] gap-[24px] text-[50px] text-white max-w-[1680px] flex justify-center md:justify-end">
        {/* line 1 */}
        <div className="border-l-[1px] absolute -z-20 left-[32px] 3xl:left-[162px] bottom-0 top-0 border-[#303739]"></div>
        {/* line 2 */}
        <div className="hidden xl:flex border-l-[1px] absolute -z-20 right-[552px] bottom-0 top-0 border-[#303739]"></div>

        <div className="flex-wrap lg:flex-nowrap flex justify-center md:pb-[120px] pb-[75px] md:justify-end">
          <div className="text-[#303739] ml-[40px] max-w-[774px] w-full font-medium flex flex-col items-start">
            <h2 className="text-[#CFC8C6] sm:text-[96px] text-[48px] mb-[20px] flex-col sm:mb-[64px] sm-[20px] sm:leading-[76px] leading-[40px]">
              Sound design
            </h2>

            <div className="max-w-[318px] mb-[36px] sm:mb-[48px] sm:max-w-[498px] text-[#303739] flex text-[14px] leading-[18px] tracking-[0.56px] md:tracking-[0.48px] md:text-[16px] md:leading-[20px]">
              Normally we find that speakers interfere with interior design, or
              at best, disappear into it. Our pieces are conceived in harmony
              with moods and decor, as functional sound sculptures that enhance
              the multi-sensory beauty of any place.
              <br />
              <br />
              Built by hand from premium materials and finishes, as objects,
              they will last a lifetime. As technology, they will too—all our
              products are designed with simplicity and sustainability in mind,
              future-proofed against obsolescence. Waves and Frequencies gear
              stays in sync with evolving hardware and protocols, so their
              compatibility is as timeless as their sound.
            </div>

            <Link
              to="/sp01-info"
              className="mb-[36px]"
            >
              <RedirectButton text={"Learn More"} />
            </Link>
          </div>

          <div className="lg:max-w-[666px] w-full flex justify-center">
            <img
              src={second}
              alt=""
            />
          </div>
        </div>
      </div>

      {/* Bottom Gradient */}
      <div className="relative w-full h-[390px] md:h-[600px] flex justify-center">
        <div className="max-w-[1680px] relative w-full">
          {/* line 1 */}
          <div className="border-l-[1px] absolute -z-20 left-[32px] 3xl:left-[162px] bottom-0 top-0 border-[#303739]"></div>
          {/* line 2 */}
          <div className="hidden xl:flex border-l-[1px] absolute -z-20 right-[552px] bottom-0 top-0 border-[#303739]"></div>
        </div>

        <div className="w-full absolute bottom-0 bg-gradient-to-b from-[#7D715000] to-[#7D7150] h-[390px] md:h-[600px]"></div>
      </div>

      {/* Footer text */}
      <div className="bg-[#7D7150] w-full z-10 md:pb-[80px] md:pt-[48px] md:px-[24px] pt-[16px] pb-[48px] px-[16px] flex justify-center">
        <a href="/8213940371676">
          <img
            src={textFooter}
            alt=""
          />
        </a>
      </div>
    </section>
  );
};

export default Forth;
