import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const Page = ({ blok }) => (
    <main {...storyblokEditable(blok)} className="px-4">
        {blok.body
            ? blok.body.map((blok) => (
                <StoryblokComponent blok={blok} key={blok._uid} />
            ))
            : null}
    </main>
);

export default Page;