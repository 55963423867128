import image from "../../Assets/Speakers/WAF-tilt.png";
import speak from "../../Assets/Speakers/WAF-grill.png";

import textFooter from "../../Assets/footerText.svg";

const Features = () => {
  return (
    <>
      {/* First Gradient */}
      <div className="w-full mt-20 bg-gradient-to-b from-[#fff] to-[#E8E8E3] md:h-[120px] h-[64px]"></div>
      <section className="w-full bg-[#E8E8E3] flex flex-col items-center py-10 justify-center">
        {/* Desktop */}
        <div className="xl:flex hidden max-w-[1680px] w-full gap-[24px] p-[24px] text-[#303739]">
          <div className="2xl:max-w-[528px] xl:max-w-[428px] max-h-[750px]">
            <img
              src={speak}
              alt=""
            />
          </div>

          {/* right side */}
          <div className="gap-[162px]">
            {/* Text and Title */}
            <div className="flex w-full justify-between">
              <p className="max-w-[390px] border-2 text-[16px] leading-[20px] tracking-[0.48px]">
                Magnetic grill
                <br />
                <br /> Each speaker grill connects and separates from the cabinet with concealed magnets—and our designer fabrics that enclose it are acoustically transparent.
              </p>
              <h2 className="flex text-[#fff] text-center justify-end w-full text-[96px] leading-[76px]">
                Features
              </h2>
            </div>
            {/* Second Image */}
            <div className="flex max-w-[940px] w-full gap-[24px] mt-[162px] ml-[10px] 2xl:ml-[112px]">
              <img
                src={image}
                alt=""
                className="2xl:max-w-[528px] xl:max-w-[428px]"
              />
              <p className="max-w-[390px] border-2 text-[16px] leading-[20px] tracking-[0.48px]">
                Signature tilt
                <br />
                <br /> SP-01’s distinctive, arched frame is cradled by an aluminum base that allows the speaker angle to be easily adjusted, directing the mid and high frequencies to your listening position without the need for a stand.
              </p>
            </div>
          </div>
        </div>

        {/* Mobile */}
        <div className="xl:hidden flex flex-col w-full gap-[64px] p-[16px] text-[#303739]">
          <h2 className="flex text-[#fff] text-center justify-end w-full text-[50px] leading-[40px]">
            Features
          </h2>

          {/* Left Align */}
          <div className="flex justify-start">
            <div className="w-full">
              <div className="w-[85%] flex flex-col">
                <img
                  src={speak}
                  alt=""
                />

                <div className="flex justify">
                  <p className="w-full mt-[32px] text-[16px] sm:text-[24px] sm:leading-[26px] leading-[19.2px] tracking-[0.48px]">
                    Magnetic grill
                    <br />
                    <br /> Each speaker grill connects and separates from the cabinet with concealed magnets—and our designer fabrics that enclose it are acoustically transparent.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Align */}
          <div className="w-full flex">
            <div className="w-full flex justify-end">
              <div className="w-[85%] flex flex-col">
                <img
                  src={image}
                  alt=""
                />
                <div className="w-full flex justify-end">
                  <p className="w-full mt-[32px] text-[16px] sm:text-[24px] sm:leading-[26px] leading-[19.2px] tracking-[0.48px]">
                    Signature tilt
                    <br />
                    <br /> SP-01’s distinctive, arched frame is cradled by an aluminum base that allows the speaker angle to be easily adjusted, directing the mid and high frequencies to your listening position without the need for a stand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Gradient Connection */}
      <div className="relative">
        <div className="h-[140px] absolute -z-10 md:h-[600px] bg-[#E8E8E3] w-full"></div>
        <div className="h-[140px] z-10 md:h-[600px] bg-gradient-to-b from-[#7D715000] to-[#7D7150] w-full"></div>
      </div>

      <div className="bg-[#7D7150] z-10 md:pb-[80px] md:pt-[48px] md:px-[24px] pt-[16px] pb-[48px] px-[16px] flex justify-center">
        <a href="/8213940371676">
          <img
            src={textFooter}
            alt=""
          />
        </a>
      </div>
    </>
  );
};

export default Features;
