import { createContext, useState } from "react";

export const ShopContext = createContext();

const ShopContextProvider = ({ children }) => {
    const [productList, setProductList] = useState([]);
    const [checkout, setCheckout] = useState(null);

    return (
        <ShopContext.Provider
            value={{
                productList,
                setProductList,
                checkout,
                setCheckout
            }}
        >
            {children}
        </ShopContext.Provider>
    );
};

export default ShopContextProvider;
