import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const Grid = ({ blok }) => {
    return (
        <div
            {...storyblokEditable(blok)}
            className="max-w-[804px] mb-[150px] mx-auto grid md:grid-cols-1 gap-3 my-12 place-items-center"
        >
            {blok.columns.map((blok) => (
                <StoryblokComponent blok={blok} key={blok._uid} />
            ))}
        </div>
    );
};

export default Grid;