import Hero from "./Hero";
import Second from "./WavesAndFreq";
import Third from "./Introducing";
import Forth from "./SoundDesign";
import ReactGA from 'react-ga4';

const Main = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Main" });

  return (
    <>
      <Hero />
      <Second />
      <Third />
      <Forth />
    </>
  );
};

export default Main;
