import image from "../../Assets/Speakers/WAF SP-01 - 06.jpg";
import mob1 from "../../Assets/bottom.png";
import mob2 from "../../Assets/bottom1.png";

const Gradient = () => {
  return (
    <>
      <div className="w-full mt-20 bg-gradient-to-b from-[#fff] to-[#E8E8E3] md:h-[120px] h-[64px]"></div>
      <section className="w-full bg-[#E8E8E3] flex flex-col items-center justify-center">
        <div className="w-full py-[16px] md:pb-[24px] md:pt-[27px] flex flex-wrap lg:flex-nowrap justify-center gap-[60px] 3xl:gap-[162px] px-[16px] text-[50px] text-white max-w-[1680px]">
          {/* Image */}
          <div className="hidden lg:flex max-w-[666px] max-h-[993px] h-full w-full">
            <img
              src={image}
              alt=""
              className="w-full"
            />
          </div>

          {/* Specs */}
          <div className="max-w-[690px] w-full text-[#303739] flex flex-col">
            <h2 className="text-[#fff] text-end justify-center w-full md:text-[96px] text-[50px] md:mb-[114px] mb-[64px] leading-[40px] md:leading-[76px]">
              Sound
            </h2>

            {/* text */}
            <div className="max-w-[630px] font-normal text-[#303739] flex flex-col gap-[48px] text-[20px]">
              <p className="md:text-[16px] text-[16px] md:leading-[20px] leading-[19.8px] tracking-[0.48px]">
                Crafted by music lovers for music lovers, the SP-01 delivers expansive 
                bass and articulate highs, transmitting sound’s full spectrum in a 
                mid-sized speaker. Expect a sound profile that is consistently clear, 
                powerful and doesn’t fatigue. A long-excursion 8" woofer and high 
                definition metal dome tweeter cooperate to generate generous headroom, 
                bass all the way down to 34hz, and a smooth response throughout 
                the entire room.
              </p>

              <div className="flex flex-col max-w-[528px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px] text-[13px] leading-[16px] tracking-[0.65px] w-full">
                <div className="border-[#303739] border-b py-2">
                  Audio specs
                </div>
                <div className="border-[#303739] border-b py-2">
                  Passive (pair)
                </div>
                <div className="border-[#303739] flex border-b py-2">
                  <div className="max-w-[108px] md:max-w-[170px] w-full">
                    Sensitivity
                  </div>
                  <div className="">89 decibels</div>
                </div>
                <div className="border-[#303739] flex border-b py-2">
                  <div className="max-w-[108px] md:max-w-[170px] w-full">
                    Response
                  </div>
                  <div className=""> 34hz—20HZ</div>
                </div>

                <div className="border-[#303739] flex border-b py-2">
                  <div className=" max-w-[108px] md:max-w-[170px] w-full">
                    Bass driver
                  </div>
                  <div className=""> 8 inch long excursion aluminum cone woofer</div>
                </div>

                <div className="border-[#303739] flex border-b py-2">
                  <div className="max-w-[108px] md:max-w-[170px] w-full">
                    Treble driver
                  </div>
                  <div className=""> 1 inch aluminum magnesium dome tweeter</div>
                </div>
              </div>

              <div className="flex flex-col max-w-[528px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px] text-[13px] leading-[16px] tracking-[0.65px] w-full">
                <div className="border-[#303739] border-b py-2">Dimensions</div>
                <div className="border-[#303739] flex border-b py-2">
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    Height
                  </div>
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    66cm
                  </div>
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    26in
                  </div>
                </div>
                <div className="border-[#303739] flex border-b py-2">
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    Width
                  </div>
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    28.5cm
                  </div>
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    11.25in
                  </div>
                </div>{" "}
                <div className="border-[#303739] flex border-b py-2">
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    Depth
                  </div>
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    30.5cm
                  </div>
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    12in
                  </div>
                </div>
                <div className="border-[#303739] flex border-b py-2">
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    Weight
                  </div>
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    18kg
                  </div>
                  <div className="max-w-[108px] md:max-w-[176px] w-full">
                    40lbs
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:hidden w-full flex justify-center mb-[16px] mt-[64px] gap-[16px]">
              <img
                src={image}
                alt=""
                className="w-full"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="w-full bg-gradient-to-b to-[#fff] from-[#E8E8E3] md:h-[120px] h-[64px]"></div>
    </>
  );
};

export default Gradient;
