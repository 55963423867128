import { useEffect, useState } from "react";
import { PRODUCT_CART_TITLE_MAP } from "../../constants/shopify";

const CartItem = ({ name, variant, amount, price, image, onDelete, id, updateQuantity }) => {
  const [total, setTotal] = useState(amount);
  const [optionals, setOptionals] = useState(null);

  const increaseTotal = async () => {
    setTotal((prev) => prev + 1);
    await updateQuantity(id, total + 1);
  };

  const decreaseTotal = async () => {
    if (total === 1) {
      onDelete();
      // delet from server too, this is just visual
      return;
    }
    setTotal((prev) => prev - 1);
    await updateQuantity(id, total - 1);
  };

  const getOptionals = (variant, title) => {
    const parts = variant.title.split(' / ');
    if (title == 'SP-01 Speaker (Pair)') {
      setOptionals([
        {
          name: 'Cabinet',
          value: parts[0]
        },
        {
          name: 'Grill',
          value: parts[1]
        }
      ])
    } else if (title == 'Speaker Cable - 10ft (Pair)') {

      setOptionals([
        {
          name: 'Color',
          value: parts[0]
        },
        {
          name: 'Termination',
          value: parts[1].includes('Combination') ? 'Combination' : parts[1]
        }
      ])
    } else {
      setOptionals([]);
    }
  };

  useEffect(() => {
    getOptionals(variant, name);
  }, [name]);


  return (
    <li className="sm:mb-[60px] mb-[32px] border-t boder-[1px] border-white">
      <div className="flex gap-[16px] sm:gap-[24px] pt-[16px] sm:pt-[24px]">
        <div className="h-auto h-[109px] w-[109px] ">
          <img
            src={image} // {img}
            alt=""
            className="h-full w-full"
          />
        </div>

        <div className="flex-grow flex flex-col justify-between">
          <div className="flex flex-col text-white sm:leading-[30px] leading-[20px] tracking-[0.48px] text-[16px] sm:text-[24px]">
            <span>{name} </span>
            {optionals && optionals.length > 0 ? optionals?.map((item, idx) => (
              <span key={idx}>
                {item.name}: {item.value}
              </span>
            )) : ''}
          </div>

          {/* Amount and price */}
          <div className="w-full flex justify-end">
            <div className="flex w-full flex-row justify-between lg:flex-col items-end text-white sm:leading-[30px] leading-[20px] tracking-[0.48px] text-[16px] sm:text-[24px]">
              <div className="lg:gap-[15px] gap-[20px] flex mr-2">
                <button
                  onClick={decreaseTotal}
                  className="w-[10px]"
                >
                  -
                </button>{" "}
                <span className="">{total}</span>{" "}
                <button
                  onClick={increaseTotal}
                  className="w-[10px]"
                >
                  +
                </button>
              </div>

              {/* Price */}
              <span>${(price * amount).toLocaleString()} USD</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartItem;
