import second from "../../Assets/S907652-R4-036-16A 1.jpg";
import "../../index.css";
import RedirectButton from "../../components/RedirectButton";
import { Link } from "react-router-dom";

const Second = () => {
  return (
    <section className="w-full relative flex flex-col items-center justify-center">
      <div className="relative w-full pt-[64px] md:pt-[409px] pb-[109px] md:pb-[184px] flex-wrap lg:flex-nowrap px-[24px] gap-[36px] text-white max-w-[1680px] flex justify-center md:justify-end">
        {/* line 1 */}
        <div className="border-l-[1px] absolute -z-20 left-[32px] 3xl:left-[162px] bottom-0 top-0 border-[#303739]"></div>

        {/* line 2 */}
        <div className="hidden xl:flex border-l-[1px] absolute -z-20 right-[552px] bottom-0 top-0 border-[#303739]"></div>

        <div className="text-[#303739] ml-[40px] max-w-[774px] w-full font-medium flex flex-col items-start">
          <h2 className="flex text-[#CFC8C6] sm:text-[96px] text-[48px] mb-[20px] flex-col sm:mb-[64px] sm-[20px] sm:leading-[76px] leading-[40px]">
            <span>Waves and</span>
            <span>Frequencies</span>
          </h2>

          <div className="max-w-[318px] mb-[36px] sm:mb-[48px] sm:max-w-[498px] text-[#303739] flex text-[14px] leading-[18px] tracking-[0.56px] md:tracking-[0.48px] md:text-[16px] md:leading-[20px]">
            We’re Waves and Frequencies—two friends brought together by music,
            15 years deep into a passion project to craft hi-fi speakers
            that look and sound exquisite. Our dream has always been to make
            perfect audio an ordinary part of hanging out, and now we’re ready
            to start the party.
          </div>

          <Link to="/info">
            <RedirectButton text={"Learn More"} />
          </Link>
        </div>

        <div className="lg:max-w-[666px] w-full flex justify-center">
          <img
            src={second}
            alt=""
          />
        </div>
      </div>
      {/* Gradient */}
      <div className="h-[64px] absolute bottom-0 md:h-[120px] bg-gradient-to-b to-[#E8E8E3] from-[#E8E8E300] w-full"></div>
    </section>
  );
};

export default Second;
